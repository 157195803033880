<template>
	<div class="same-list">
		<div class="same-title">
			<div class="same-title-ch">{{ pageConfig.title }}</div>
			<div class="same-title-en" v-if="pageConfig.titleEn">{{ pageConfig.titleEn }}</div>
		</div>
		<div class="carsite-table"
			v-if="!noPageListCodes.includes(pageCode) && pagination.total > 0 && this.pageConfig.request !== false">
			<div class="same-wrap-list">
				<div class="same-wrap-item" v-for="item in list" :key="item.id" @click="toDetail(item)">
					<div class="same-wrap-c">
						<div class="same-wrap-title">
							<div class="wrap-title-image">
								<img :src="item.path" alt="" />
							</div>
							<div class="wrap-title-calendar">
								<span class="wrap-title-month">{{ item.createTime.slice(-5) }}</span>
								<span class="wrap-title-year">{{ item.createTime.slice(0, 4) }}</span>
							</div>
						</div>
						<div class="same-wrap-content">
							<div class="wrap-content-text">
								<div class="same-content-title">
									{{ item.title }}
								</div>
								<div class="same-content-word">
									{{ item.abstractTitle }}
								</div>
							</div>
							<div class="wrap-detail-icon">></div>
						</div>
					</div>
				</div>
			</div>
			<div class="same-wrap-pagination">
				<el-pagination background prev-text="上一页" next-text="下一页" layout="total, prev, pager, next"
					@current-change="handleScrapChange" :current-page="pagination.pageindex"
					:page-size="pagination.pageSize" :total="pagination.total">
				</el-pagination>
			</div>
		</div>
		<div v-if="noPageListCodes.includes(pageCode) && this.pageConfig.request !== false" class="videoCenter">
			<div class="video-center-list">
				<div class="video-center-item" v-for="item in videoList" :key="item.id">
					<div class="video-center-image">
						<img :src="item.imgPath" alt="" />
						<div class="video-icon" @click="onClickVideoIcon(item)"></div>
					</div>
					<div class="video-center-bottom">
						<div class="video-center-title">
							{{ item.title }}
						</div>
						<div class="video-center-time">
							<div class="video-center-month">
								<img src="/img/dashboard/date.png" alt="" />
								<span>{{ item.creationTime }}</span>
								<!-- <span>{{item.createTime}}</span> -->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="same-wrap-pagination">
				<el-pagination background prev-text="上一页" next-text="下一页" layout="total, prev, pager, next"
					@current-change="handleScrapChange" :current-page="viodePagination.pageindex"
					:page-size="viodePagination.pageSize" :total="viodePagination.totalCount">
				</el-pagination>
			</div>
			<video-modal :dialogVisible="dialogVisible" @handleClose="handleClose" :path="videoPath" />
		</div>
		<div v-if="noPageListCodes.includes(pageCode) && pageConfig.request === false" class="carsite-about">
			<div v-html="GywmQuery.content" class="carsite-about-content" />
			<div class="carsite-about-swiper">
				<swiper class="swiper" :options="swiperOption" direction="vertical">
					<swiper-slide v-for="(item, index) in swiperData" :key="index" class="slide">
						<img :src="item.url" alt="" />
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
		</div>
		<div class="no-data"
			v-if="((pagination.total === 0 && this.pageCode === 'companyCulture') || (viodePagination.totalCount === 0 && this.pageCode === 'videoCenter')) && this.pageConfig.request !== false">
			没有找到相关信息！</div>
	</div>
</template>
<script>
import { pageConfigData } from './config'
import SearchCache from './search-cache'
import { mapState } from "vuex";
import VideoModal from './video-modal.vue'

const noPageListCodes = ['videoCenter', 'aboutUs']

export default {
	name: 'sameList',
	components: {
		VideoModal
	},
	data() {
		return {
			pageCode: '',
			videoPath: '',
			pageConfig: {},
			dialogVisible: false,
			requestParams: {
				PageSize: 8,
				PageIndex: 1
			},
			noPageListCodes,
			swiperData: [
				{
					url: '/img/dashboard/us1.png'
				},
				{
					url: '/img/dashboard/us2.png'
				},
				{
					url: '/img/dashboard/us3.png'
				},
				{
					url: '/img/dashboard/us4.png'
				},
				{
					url: '/img/dashboard/us5.png'
				},
				{
					url: '/img/dashboard/us6.png'
				}
			],
			swiperOption: {
				observer: true,	// 改变swiper的样式或者修改swiper的子元素时，自动初始化swiper
				observeParents: true, // 当swiper的祖先元素变化时，swiper更新
				observeSlideChildren: true,	// 子slide更新时，swiper更新
				spaceBetween: 30,
				//显示分页
				pagination: {
					clickable: true,
					el: ".swiper-pagination",
				},
				slidesPerView: 3
			},
		};
	},
	watch: {
		'$route'(to, from) {
			if (to.path !== from.path) {
				SearchCache.set({})
			}
			if (this.$route.params && this.$route.params.pathMatch) {
				this.pageCode = this.$route.params.pathMatch
				this.pageConfig = pageConfigData[this.pageCode]
				let _params = SearchCache.get() || {}
				let { FindKeyWords } = this.$route.query
				_params.WebSiteType = this.$route.query.index
				if (FindKeyWords || _params.WebSiteType === '0') {
					_params.FindKeyWords = FindKeyWords
				}
				if (this.pageConfig.requestParams) {
					this.requestParams = {
						...this.requestParams,
						...this.pageConfig.requestParams
					}
				}
				if (this.pageConfig.request === false && document.querySelector('#mainContainer') && document.querySelector('#mainContainer').scrollTop > 0) {
					document.querySelector('#mainContainer').scrollTop = 0
				}
				this.pageConfig.request !== false && this.fetch({
					PageSize: 8,
					PageIndex: 1,
					request: this.pageConfig.request,
					..._params
				}, true);
				SearchCache.set({})
				if (this.noPageListCodes.includes(this.pageCode) && this.pageConfig.request === false) {
					this.fetchGywm()
				}
			}
			// document.querySelector('.main').scrollTop = 0 //.main是本项目中overFlow：auto的盒子
		}
	},
	computed: {
		...mapState({
			list: state => {
				return state.sameList.list;
			},
			pagination: state => {
				return state.sameList.pagination;
			},
			videoList: state => {
				return state.sameList.videoList;
			},
			viodePagination: state => {
				return state.sameList.viodePagination;
			},
			GywmQuery: state => {
				return state.sameList.GywmQuery;
			}
		}),
	},
	created() {
		if (this.$route.params && this.$route.params.pathMatch) {
			this.pageCode = this.$route.params.pathMatch
			this.pageConfig = pageConfigData[this.pageCode]
			let _params = SearchCache.get() || {}
			let { FindKeyWords } = this.$route.query
			_params.WebSiteType = this.$route.query.index
			if (FindKeyWords || _params.WebSiteType === '0') {
				_params.FindKeyWords = FindKeyWords || ''
			}
			if (this.pageConfig.request) {
				_params.request = this.pageConfig.request
			}
			if (this.pageConfig.requestParams) {
				this.requestParams = {
					...this.requestParams,
					...this.pageConfig.requestParams
				}
				_params = {
					..._params,
					...this.pageConfig.requestParams
				}
			}
			this.pageConfig.request !== false && this.fetch({
				...this.requestParams,
				..._params
			}, true);
			if (this.noPageListCodes.includes(this.pageCode) && this.pageConfig.request === false) {
				this.fetchGywm()
			}
		}
	},
	methods: {
		fetch(payload, coverage) {
			let _params = coverage ? payload : {
				...this.requestParams,
				...payload
			}
			let { request = 'querySearchList', ...otherParams } = _params
			this.$store.dispatch(`sameList/${request}`, otherParams).then(() => {
				let cache = _params
				SearchCache.set(cache)
			})
			if (document.querySelector('#mainContainer') && document.querySelector('#mainContainer').scrollTop > 0) {
				document.querySelector('#mainContainer').scrollTop = 0
			}
		},
		fetchGywm() {
			this.$store.dispatch("sameList/fetchGywm")
		},
		onClickVideoIcon(item) {
			if (item && item.path) {
				this.videoPath = item.path
			}
			this.dialogVisible = true
		},
		handleClose() {
			this.dialogVisible = false
		},
		toDetail(selectItem) {
			this.$router.push({
				path: `/carsite/same-list/${this.pageCode}/detail`,
				query: {
					id: selectItem.id,
					index: this.$route.query.index,
					pageCode: this.pageCode
				}
			});
		},
		handleScrapChange(index) {
			let _params = SearchCache.get() || {}
			this.fetch({
				..._params,
				PageIndex: index
			});
			// console.log(index);
		}
	}
}
</script>
<style lang="less" scoped>
@fontColor: #032E81;

.wrapWidth {
	width: 93%;
	max-width: 1200px;
	margin: 0 auto;
}

.same-list {
	margin-top: 70px;

	.same-title {
		text-align: center;

		.same-title-ch {
			font-size: 30px;
			color: @fontColor;
			font-weight: 100;
		}

		.same-title-en {
			font-size: 18px;
			color: rgb(255 102 0);
			line-height: 36px;
		}
	}

	.same-wrap-list {
		.same-wrap-item {
			margin-top: 25px;
			cursor: pointer;

			.same-wrap-c {
				.wrapWidth;
				padding-bottom: 25px;
				border-bottom: 1px solid rgb(198 198 198);
			}

			.same-wrap-title {
				width: 41%;
				display: inline-block;
				vertical-align: middle;
				border-right: 1px solid rgb(214 214 214);
			}

			.wrap-title-image {
				display: inline-block;
				vertical-align: middle;
				width: 56%;
				overflow: hidden;
				max-height: 280px;

				>img {
					width: 100%;
				}
			}

			.wrap-title-calendar {
				display: inline-block;
				vertical-align: middle;
				text-align: right;
				width: 40%;
				padding-right: 4%;

				.wrap-title-month {
					width: 100%;
					font-size: 30px;
					font-weight: bold;
					display: inline-block;
					color: rgb(51, 51, 51);
				}

				.wrap-title-year {
					width: 100%;
					font-size: 18px;
					display: inline-block;
					color: rgb(153, 153, 153);
				}
			}

			.wrap-content-text {
				display: inline-block;
				vertical-align: middle;
				width: 88%;
			}

			.same-content-title {
				font-size: 20px;
				color: rgb(51, 51, 51);
			}

			.same-content-word {
				font-size: 14px;
				color: rgb(102 102 102);
				line-height: 30px;
				margin-top: 30px;
			}

			.wrap-detail-icon {
				display: inline-block;
				vertical-align: middle;
				width: 40px;
				height: 40px;
				line-height: 40px;
				color: rgb(21 60 137);
				background: rgb(238 238 238);
				text-align: center;
				font-size: 20px;
			}

			.same-wrap-content {
				display: inline-block;
				vertical-align: middle;
				width: 55%;
				margin-left: 3%;
			}

			&:hover {
				background: rgb(238, 238, 238);

				.wrap-title-calendar {

					.wrap-title-month,
					.wrap-title-year {
						color: @fontColor;
					}
				}

				.same-content-title {
					color: @fontColor;
				}

				.wrap-detail-icon {
					background: @fontColor;
					color: rgb(255, 255, 255);
				}
			}
		}
	}

	.same-wrap-pagination {
		text-align: center;
		margin: auto;
		margin-top: 50px;
		font-size: 0;

		/deep/.btn-prev,
		/deep/.btn-next {
			padding-left: 8px;
			padding-right: 8px;
		}

		/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
			background-color: @fontColor;
		}
	}

	.no-data {
		text-align: center;
		font-size: 15px;
		color: rgb(153, 153, 153);
		line-height: 80px;
	}

	.video-center-list {
		.wrapWidth;
		margin-top: 40px;

		.video-center-item {
			width: 30.6%;
			margin-right: 2%;
			background: rgb(240, 240, 240);
			margin-bottom: 50px;
			padding-bottom: 20px;
			display: inline-block;
			vertical-align: middle;
		}

		.video-center-image {
			width: 100%;
			height: 180px;
			position: relative;

			img {
				width: 100%;
				height: 100%;
			}

			.video-icon {
				cursor: pointer;
				display: inline-block;
				background-image: url(/img/dashboard/playicon@2x.png);
				background-size: 100% 100%;
				position: absolute;
				width: 70px;
				height: 70px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.video-center-bottom {
			width: 93%;
			margin: auto;

			.video-center-title {
				font-size: 16px;
				color: rgb(51, 51, 51);
				padding-top: 20px;

				&:hover {
					color: rgb(3, 46, 129);
				}
			}

			.video-center-time {
				margin-top: 25px;
			}

			.video-center-month {
				overflow: hidden;
				vertical-align: middle;
				margin-right: 20px;
				font-size: 14px;
				color: rgb(153, 153, 153);

				img {
					display: inline-block;
					vertical-align: middle;
					filter: grayscale(100%);
				}

				&:hover {
					img {
						filter: none;
					}
				}

				span {
					display: inline-block;
					vertical-align: middle;
					margin-left: 2%;
				}
			}
		}
	}

	.carsite-about {
		.wrapWidth;
		font-size: 16px;
		color: rgb(102, 102, 102);
		line-height: 32px;

		.carsite-about-content {
			text-indent: 2em;
		}

		.carsite-about-swiper {
			margin-top: 30px;

			.swiper-slide {
				>img {
					width: 100%;
				}
			}

			>.swiper {
				padding-bottom: 40px;
			}
		}

		/deep/.swiper-pagination {
			bottom: -10px;
			left: 0;
			width: 100%;

			.swiper-pagination-bullet {
				width: 14px;
				height: 14px;
				border: #ff6600 solid 1px;
				background-color: rgba(255, 255, 255, 0.1);
				opacity: 1;
				border-radius: 10px;
				margin: 0 10px;
			}

			.swiper-pagination-bullet.swiper-pagination-bullet-active {
				background-color: #ff6600;
			}
		}
	}
}</style>
export const pageConfigData = {
	corporateNews: {
		title: '公司要闻',
		titleEn: 'Corporate News'
	},
	grassrootsDynamic: {
		title: '党的建设',
		titleEn: 'Party Building'
	},
	companyCulture: {
		title: '企业文化',
		titleEn: 'Corporate Culture'
	},
	videoCenter: {
		title: '视频中心',
		titleEn: 'Video Center',
		request: 'getListPage',
		requestParams: {
			PageSize: 9,
			PageIndex: 1
		}
	},
	aboutUs: {
		title: '关于我们',
		titleEn: 'About Us',
		request: false
	},
	staffStyle: {
		title: '文化园地',
		titleEn: 'Cultural Park'
	},
	// videoCenter: {
	// 	title: '文化园地',
	// 	titleEn: 'Video Center'
	// },
	groupNews: {
		title: '集团新闻',
		titleEn: 'Group News'
	},
	noticeInfo: {
		title: '通知公告',
		titleEn: 'Notice'
	},
	searchKeywords: {
		title: '搜索结果'
	}
}

/* global window */
import Lodash from 'lodash'

const { history } = window

const clear = () => {
  history.replaceState(null, '')
}

export default {
  set: (searchData) => {
    if (Lodash.isEmpty(searchData)) {
      clear()
    } else if (history.state) {
      history.replaceState({
        ...history.state,
        ...searchData
      }, '')
    } else {
      history.replaceState(searchData, '')
    }
  },
  get: () => {
    if (!history.state) {
      return history.state
    }
    // key,hash是router自己加的参数，需过滤
    const { key, hash, ...rest } = history.state
    return rest
  },
  clear
}
